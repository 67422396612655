.mobile-table-item {
	@apply px-6 pb-4;
	&:nth-child(odd) {
		@apply bg-gray-8;
	}
	& > h3 {
		@apply pt-2 pb-3 text-darkGray-600 font-bold;
	}
}
.pneumatic-bloc {
	& > div {
		@apply text-sm;
	}
	& > div > div {
		@apply flex flex-row justify-between pl-3 py-1;
		& > span {
			&:first-child {
				@apply w-1/2 pl-2 font-semibold text-darkGray-600;
			}
			&:last-child {
				@apply w-1/2 text-gray-400;
			}
		}
	}
}
