.clip {
	@apply inline-block;
	transform: skew(-45deg);
}

.error-shadow {
	@apply overflow-hidden;
	height: 100px;
}

.clip:nth-of-type(2) .error-shadow {
	@apply overflow-hidden relative;
	width: 75px;
	box-shadow: inset 20px 0 20px -15px rgba(150, 150, 150, 0.8),
		20px 0 20px -15px rgba(150, 150, 150, 0.8);
}

.clip:first-child .error-shadow,
.clip:last-child .error-shadow {
	@apply overflow-hidden relative;
	width: 80px;
	box-shadow: inset 20px 0 20px -15px rgba(150, 150, 150, 0.8),
		20px 0 20px -15px rgba(150, 150, 150, 0.8);
}

.digit {
	@apply relative inline-block bg-blue-275 text-white font-bold font-bold text-6xl rounded-full;
	top: 8%;
	width: 80px;
	height: 80px;
	line-height: 80px;
	transform: skew(45deg);
}

.clip:nth-of-type(2) .digit {
	left: -10%;
}

.clip:first-child .digit {
	right: -20%;
}

.clip:last-child .digit {
	left: -20%;
}

@screen sm {
	.clip:nth-child(2) .error-shadow {
		width: 100px;
	}

	.clip:first-child .error-shadow,
	.clip:last-child .error-shadow {
		width: 120px;
	}
}

@screen tablet {
	.error-shadow {
		height: 180px;
	}

	.clip:nth-of-type(2) .error-shadow {
		width: 130px;
	}

	.clip:first-child .error-shadow,
	.clip:last-child .error-shadow {
		width: 250px;
	}

	.digit {
		width: 150px;
		height: 150px;
		line-height: 150px;
	}
}
