details summary {
	list-style: none;
	touch-action: manipulation;
}

details[open] .summary-icon {
	transform: rotate(90deg);
}

.summary-icon {
	font-size: 0.9rem;
	transition: transform 0.3s ease;
}
