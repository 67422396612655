.fullscreen-modal {
	@apply mx-0 z-60 !important;
}

.image-gallery {
	&-thumbnails-container {
		@apply w-24;
	}

	&-thumbnails {
		@apply overflow-x-auto overflow-y-hidden;
		-webkit-overflow-scrolling: touch;
	}

	&-slides {
		@apply md:rounded-t-lg;
	}

	&-image {
		@apply bg-gray-900;
		min-height: 56.5vw;
	}

	&-svg {
		@apply h-4;
	}

	@screen md {
		&-image {
			@apply min-h-108;
		}
	}

	.fullscreen {
		@apply h-full w-full;

		.image-gallery-image,
		.image-gallery-slide-wrapper,
		.image-gallery-slide-wrapper {
			@apply block bg-black relative w-full h-screen max-h-full pb-20;
			touch-action: pinch-zoom pan-x;
		}

		.image-gallery-thumbnails-wrapper {
			@apply bottom-1 absolute w-full overflow-hidden;
		}

		.image-gallery-thumbnail {
			@apply border-black border w-25 !important;
		}

		.image-gallery-slides {
			@apply overflow-hidden;
		}

		.image-gallery-fullscreen-button {
			touch-action: manipulation;
		}

		.image-gallery-left-nav,
		.image-gallery-right-nav {
			touch-action: manipulation;
		}
	}

	.image-gallery-thumbnail {
		@apply w-25;
	}

	.image-gallery-icon:focus {
		outline: none !important;
	}
}
