.blog-post-content {
	ul {
		@apply list-disc my-3 ml-4 list-outside text-gray-800;
	}
	ol {
		@apply list-decimal list-inside;
	}
	li {
		@apply mt-2 mb-2;
	}
	h3 {
		@apply font-semibold mt-3 mb-2;
	}
	p {
		@apply my-3 text-gray-800;
	}

	table {
		@apply my-3;
	}

	table,
	th,
	td {
		@apply border p-2;
	}
	h2,
	h3,
	h4,
	h5 {
		@apply mt-6 mb-4 font-bold text-gray-800;
	}

	a {
		@apply text-blue-275;
	}

	iframe {
		@apply m-auto;
	}
}
