.anchor {
	@apply block relative invisible;
	top: calc(-$headerHeight - 1rem);
}

/* media query "lg" to be consistent with lg:sticky attribute applied to the same element */
@media (min-width: 1100px) {
	.sidebar-container {
		top: $headerHeight;
	}
}

.nav-active-item {
	@apply font-extrabold relative;
	& a::before {
		@apply border-orange-400 border-solid absolute inline-block;
		content: '';
		left: -1rem;
		top: 5px;
		border-width: 0 3px 3px 0;
		padding: 3px;
		transform: rotate(-45deg);
	}
}

span.dot::before {
	content: '•';
}
