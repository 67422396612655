.transitionHeightEase {
	transition: height 250ms ease;
}

.transitionAllEaseOut {
	transition: all 200ms ease-in-out;
}

.transitionAllCubic {
	transition: all 200ms cubic-bezier(0.25, 0.8, 0.25, 1);
}
