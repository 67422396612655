.kr-embedded {
	@apply m-auto text-center !important;
}

.kr-payment-button {
	@apply bg-green-550 rounded !important;
}

.kr-payment-button:hover {
	@apply bg-green-750 !important;
}

.kr-form-error {
	@apply invisible h-0 !important;
}
