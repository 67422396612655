.btn {
	transition: background-color 0.15s ease-in-out;
	touch-action: manipulation;
	@apply flex justify-center items-center text-center px-6 py-3 rounded font-semibold shadow;
}

.btn-link {
	@apply inline-block;
}

.btn-small {
	@apply px-2 py-1 font-normal;
}

.btn-primary {
	@apply text-white bg-blue-275 border-2 border-blue-275;

	&:hover {
		@apply text-white  bg-blue-300 border-blue-300;
	}
	&-disabled {
		@apply text-lightBlue-500 bg-white border-2 border-lightBlue-500;
	}
}

.btn-secondary {
	@apply text-blue-275 border-2 border-blue-275 bg-white;
	&:hover {
		@apply text-white bg-blue-300 border-blue-300;
	}
	&-disabled {
		@apply text-lightBlue-500 bg-white border-2 border-lightBlue-500;
	}
}

.btn-tertiary {
	@apply text-gray-900 bg-transparent border-2 border-gray-900;
	&:hover {
		@apply text-white bg-gray-900 border-2 border-gray-900;
	}
	&-disabled {
		@apply text-gray-20 bg-transparent border-2 border-gray-20;
	}
}

.btn-green {
	@apply text-white bg-green-550 border-2 border-green-550;

	&:hover {
		@apply text-white bg-green-750 border-green-750;
	}

	&-disabled {
		@apply text-white bg-lightBlue-250;
	}
}

.btn-green-inverse {
	@apply text-green-550 border-2 border-green-550 bg-white;

	&:hover {
		@apply text-white bg-green-750 border-green-750;
	}

	&-disabled {
		@apply text-white bg-lightBlue-250;
	}
}

.btn-white {
	@apply text-white bg-transparent border-2 border-white;

	&:hover {
		@apply text-white bg-green-750;
	}

	&-disabled {
		@apply text-white bg-lightBlue-250;
	}
}

.btn-outline {
	transition: background-color 0.15s ease-in-out;
	@apply flex justify-center items-center text-center px-6 py-3 rounded font-semibold;
}

.btn-outline-green {
	@apply bg-transparent border text-green-550 border-green-550 shadow-none;

	&:hover {
		@apply text-green-750 border-green-750;
	}
	&-disabled {
		@apply opacity-50;
	}
}

.btn-outline-lightgray {
	@apply bg-transparent border border-gray-50 text-gray-50 shadow-none;

	&-disabled {
		@apply opacity-50;
	}
}

.btn-outline-icon {
	transition: background-color 0.15s ease-in-out;
	box-shadow: 0 0 18px rgba(0, 0, 0, 0.15);
	@apply bg-white border border-gray-10 inline-block text-center px-4 py-2 rounded-full font-semibold;
}

.QuestionButton {
	@apply px-3 py-3 font-semibold rounded bg-white text-blue-275 relative;

	&-active {
		@apply bg-blue-275 text-white;

		&::after {
			@apply absolute w-0 h-0 border-r-8 border-blue-275;
			content: '';
			bottom: -10px;
			margin-left: -3px;
			left: 50%;
			border-top: 8px solid transparent;
			border-bottom: 8px solid transparent;
			transform: rotate(-90deg);
		}
	}
}

.btn-contact {
	@apply text-white bg-blue-275 border-blue-275;
	&:hover {
		@apply text-white bg-blue-700 border-blue-700;
	}
	&.btn-outline {
		@apply bg-transparent border text-white border-white;
		&:hover {
			@apply bg-blue-700 border text-white border-white;
		}
	}
}
