.form-radio {
	@apply absolute block bg-gray-10 h-4 w-4 mt-1 rounded-full;
}

.form-radio::before {
	@apply block absolute m-auto rounded-full h-2 w-2 top-0_8 left-0_8;
	content: '';
}

.form-radio:checked {
	@apply bg-blue-275;
}

.form-radio:focus {
	@apply outline-none shadow-none;
}

:checked + .checked-sibling {
	@apply bg-gray-17;
}
