.dropdown:focus + .dropdown-menu {
	@apply flex;
}

.dropdown:hover .dropdown-menu {
	@apply flex;
}

{
	.menu-shadow {
		box-shadow: 0 0 16px 0 rgba(113, 97, 167, 0.2);
	}
}
