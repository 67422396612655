* {
	font-family: 'Mulish', sans-serif;
}

html {
  line-height: 1.25;
}

.text-between-lines {
	overflow: hidden;
	text-align: center;

	&:before,
	&:after {
		@apply inline-block w-1/2 align-middle bg-white;
		content: '';
		height: 1px;
	}

	&:before {
		right: 0.5em;
		margin-left: -50%;
	}
}

.title-1 {
	@apply flex flex-col mb-10 text-3xl font-bold text-darkBlue-500 relative pb-2;

	&-white {
		@apply text-white;
	}

	&::after {
		@apply absolute w-16 h-1 bg-orange-500 bottom-0;
		/* -2rem because w-16 == 4rem */
		left: calc(50% - 2rem);
		content: '';
	}
}

.title-2 {
	@apply text-xl font-bold text-gray-900 relative mt-8 mb-4;

	&-blue {
		@apply text-blue-275;
	}

	&-little-marge {
		@apply my-3;
	}
}

.bullet {
	&::before {
		@apply inline-block bg-blue-275 mr-3 rounded;
		content: '';
		width: 5px;
		height: 5px;
	}

	&--green {
		&::before {
			@apply bg-green-700;
		}
	}

	&--orange {
		&::before {
			@apply bg-orange-700;
		}
	}

	&--red {
		&::before {
			@apply bg-red-700;
		}
	}
}

.capitalizeFirstLetter::first-letter {
	@apply capitalize;
}

hr {
	height: 0.5px;
	@apply border-none text-separator bg-separator;
}
