.product-list {
	@apply flex flex-wrap w-full justify-center;
}

.product-item {
	@apply flex flex-col w-full pb-3;
}

@screen md {
	.product-item {
		@apply flex flex-col w-1/2 p-2;
	}
}

@screen tablet {
	.product-item {
		@apply flex flex-col w-1/3 p-2;
	}
}

.search-box {
	@apply absolute bg-white touch-manipulation left-3/4 right-4;
	transition: left 0.2s ease-in-out;

	& input::placeholder {
		color: inherit;
	}

	&:focus-within {
		@apply left-4;
	}
}

@screen md {
	.search-box {
		@apply relative w-full;
		/* The important is needed because .search-box:focus-within has more precedence */
		left: initial !important;
		right: initial !important;

		& input::placeholder {
			color: inherit;
		}
	}
}
