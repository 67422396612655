@import '../node_modules/react-image-gallery/styles/css/image-gallery.css';
@import '../node_modules/pure-react-carousel/dist/react-carousel.es.css';
@import '../node_modules/rc-tooltip/assets/bootstrap.css';
@import '../node_modules/@reach/dialog/styles.css';
@import '../node_modules/react-toastify/dist/ReactToastify.min.css';
@import '../node_modules/react-phone-input-2/lib/style.css';

@import './index.tailwind.css';

/* purgecss start ignore */
@import './variables.css';
@import './backgrounds.css';
@import './buttons.css';
@import './inputs.css';
@import './typography.css';
@import './responsive.css';
@import './animations.css';
@import './images.css';
@import './menu.css';
@import './transform.css';
@import './icons.css';
@import './reach-ui.css';
@import './svg.css';
@import './rheostat.css';
@import './productList.css';
@import './verticalTimeline.css';
@import './ImageGallery.css';
@import './table.css';
@import './filterAccordion.css';
@import './filterAside.css';
@import './nav.css';
@import './defects.css';
@import './formChecbox.css';
@import './formRadio.css';
@import './error.css';
@import './arrow.css';
@import './links.css';
@import './blog.css';
@import './blog-post.css';
@import './markdown.css';
@import './carousel.css';
@import './formPayment.css';
@import './divider.css';
@import './hamburger.css';
@import './faq.css';

button,
a,
[role='button'] {
	touch-action: manipulation;
}
/* purgecss end ignore */
