.arrow:not(.flag .arrow) {
	@apply absolute inline-block border-b-4 border-r-4;
	touch-action: manipulation;
	top: 50%;
}

.arrow--right {
	transform: rotate(-45deg);
}

.arrow--left {
	transform: rotate(135deg);
}
