.defect {
	&-spot {
		@apply absolute block rounded-full bg-green-550 border-4 border-white;
		width: 20px;
		height: 20px;
		margin-left: -12px;
		margin-top: -12px;
	}

	&-tooltip {
		@apply absolute block visible;
		z-index: 20;

		&-hidden {
			@apply hidden;
		}

		&-inner {
			@apply px-3 py-3 bg-white rounded border border-gray-100;
			box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
		}

		&-picture {
			@apply max-w-xl m-auto;
		}
	}
}
