@import '@reach/tooltip/styles.css';

:root {
	--reach-tabs: 1;
}

/* tooltip  */

[data-reach-tooltip] {
	@apply w-64;
	white-space: normal !important;
}

/* dialog  */
[data-reach-dialog-overlay] {
	@apply flex items-center justify-center z-70;
	background: hsla(254, 90%, 15%, 0.3) !important;
}

body [data-reach-dialog-content] {
	@apply m-auto w-full;
	box-shadow: 0 0 20px 0 rgba(113, 97, 167, 0.16);
}

@screen tablet {
	body [data-reach-dialog-content] {
		width: 50vw;
	}
}

@screen sm {
	body [data-reach-dialog-content] {
		width: 92vw;
		border-radius: 14px;
	}
}
