.markdown {
	ul {
		@apply list-disc ml-4 list-outside text-gray-800;
	}
	ol {
		@apply list-decimal list-inside;
	}
	h3 {
		@apply font-semibold mt-3 mb-2;
	}
	p {
		@apply my-3 text-gray-800;
	}
	table,
	th,
	td {
		@apply border p-2;
	}
	h2,
	h3,
	h4,
	h5 {
		@apply mt-6 mb-4 font-bold text-gray-800;
	}

	a {
		@apply text-blue-275;
	}
}

@screen tablet {
	.markdown {
		@apply px-0;
	}
}

.markdown--justify {
	p {
		@apply text-justify;
	}
}

.markdown--strong-blue {
	strong {
		@apply text-blue-275;
	}
}

.markdown--table-blue {
	table {
		@apply border p-2 border-blue-275 w-full;
	}

	thead,
	tbody {
		@apply border-2 p-2 border-blue-275;
	}

	td {
		@apply border-0 p-2 text-sm border-l-2 border-r-2 border-blue-275;
	}

	th {
		@apply border-0 p-3 text-blue-275 border-l-2 border-r-2 border-blue-275;
	}
}
