.input-side-success {
	@apply absolute right-0;
	top: 0.75rem;
	margin-right: -3rem;
}

/* Trick for iOS box-shadow which doesn't work in forms ... */
.inputs-shadow {
	-webkit-appearance: none;
}

.touch-manipulation {
	touch-action: manipulation;

	:active,
	:focus {
		outline: none;
	}
}

.input-licenseplate {
	padding-top: 0.8rem;
	padding-bottom: 0.8rem;
}

.input-white-form {
	border-radius: 5px;
	background-color: white;
	border: 1px solid #adb3c7;
}

/* remove search field reset cross */
input[type='search']::-ms-clear,
input[type='search']::-ms-reveal {
	display: none;
	width: 0;
	height: 0;
}
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
	display: none;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type='number'] {
	-moz-appearance: textfield;
}
