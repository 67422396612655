.blog-link {
	@apply block relative w-full overflow-hidden rounded-sm;
	height: 120px;

	a {
		@apply text-white;
	}

	img {
		@apply block w-full h-full object-cover transition-all duration-300 ease-in;
	}

	&-title {
		@apply absolute flex items-end justify-center w-full h-full top-0 left-0 z-10 p-4;
		background-image: linear-gradient(
			-269deg,
			rgba(6, 83, 110, 0.8) 0%,
			rgba(6, 83, 110, 0.3) 80%
		);
	}

	&:hover {
		.blog-link-title {
			@apply text-white;
		}

		img {
			transform: scale(1.05);
		}
	}
}

@screen tablet {
	.blog-link {
		@apply h-full;

		&-title {
			@apply p-6;
		}

		&.blog-h-250 {
			img {
				@apply block object-cover w-full;
				height: 250px;
				max-height: 250px;
			}
		}

		&.blog-h-520 {
			img {
				@apply block object-cover w-full;
				height: 520px;
				max-height: 520px;
			}
		}
	}
}
