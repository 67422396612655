.link-row {
	@apply inline-flex items-center;
	&-icon {
		@apply ml-1 transition duration-200 transition-all ease-in-out;
	}
	&:hover {
		@apply no-underline;
		.link-row-icon {
			transform: translateX(2px);
		}
	}
}
