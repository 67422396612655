.carousel * {
	@apply outline-none;
}

.carousel *:hover {
	@apply outline-none;
}

$animationSpeed: 80s;
$itemSize: 450px;
$itemsNumber: 30;

@keyframes scroll {
	from {
		transform: translateX(0);
	}
	to {
		transform: translateX(calc(-$itemSize * ($itemsNumber / 2)));
	}
}

.customer-review-slider {
	@apply w-full relative h-128 overflow-x-auto;

	.customer-review-slide-track {
		@apply flex z-20;
		width: calc($itemSize * $itemsNumber);
	}

	.customer-review-slide {
		@apply flex flex-col w-108 px-3;
	}
}

@screen tablet {
	.customer-review-slider {
		@apply overflow-x-hidden;

		.customer-review-slide-track {
			animation: scroll $animationSpeed linear infinite;

			&:hover {
				animation-play-state: paused;
			}
		}
	}
}
