.hamburger-lines {
	height: 26px;
	width: 32px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	cursor: pointer;

	.line {
		display: block !important;
		height: 4px;
		width: 100%;
		border-radius: 10px;
		background: #135def;
	}

	.line1 {
		transform-origin: 0% 0%;
		transition: transform 0.4s ease-in-out;
	}

	.line2 {
		transition: transform 0.2s ease-in-out;
	}

	.line3 {
		transform-origin: 0% 100%;
		transition: transform 0.4s ease-in-out;
	}
}

.mobile-menu {
	display: none;
}

#mobile-menu-toggle:checked ~ .mobile-menu {
	display: block;
}

#mobile-menu-toggle:checked ~ .mobile-menu {
	display: block;
}

#mobile-menu-toggle:checked ~ .hamburger-lines {
	position: fixed !important;
}

#mobile-menu-toggle:checked ~ .hamburger-lines .line1 {
	transform: rotate(45deg);
}

#mobile-menu-toggle:checked ~ .hamburger-lines .line2 {
	transform: scaleX(0);
}

#mobile-menu-toggle:checked ~ .hamburger-lines .line3 {
	transform: rotate(-45deg);
}
