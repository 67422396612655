.aside {
	@apply fixed bg-white flex flex-col w-full h-full overflow-y-auto -left-full transition transition-all duration-300 ease-out z-60 top-0;
	overscroll-behavior: none;
}

.openAsideAfterProps:after {
	content: '';
	@apply absolute left-0 top-0 h-full  w-full -left-full;
}

.aside--open {
	@apply left-0;
}

@screen tablet {
	.aside {
		@apply static max-w-76 mt-0 shadow-lg h-auto z-40;
	}
}
