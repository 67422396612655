.VerticalTimeline {
	@apply relative;
}

.VerticalTimeLineElement {
	@apply relative;
	&:not(:last-child) {
		@apply pb-8;
		&::before {
			@apply absolute top-0 border-dashed border-r border-blue-275;
			content: '';
			top: 1rem;
			left: 1rem;
			bottom: -1rem;
			width: 3px;
		}
	}

	> div {
		min-height: 1px;
	}

	&-icon {
		@apply absolute bg-blue-275;
		top: 1rem;
		width: 2rem;
		height: 2rem;
		border-radius: 50%;

		svg {
			@apply block relative;
			width: 1.8rem;
			height: 1.8rem;
			left: 50%;
			top: 50%;
			margin-left: -0.7rem;
			margin-top: -0.7rem;
		}
	}

	&-content {
		@apply block relative bg-white rounded ml-12 p-4 border-2 border-gray-17 rounded-2;
		width: fit-content;
	}
}
