.status-indicator::before {
	content: '';
	@apply inline-block w-2 h-2 mr-2 align-middle rounded-full bg-label-green;
}

.status-indicator.text-label-green::before {
	@apply bg-label-green;
}

.status-indicator.text-label-red::before {
	@apply bg-label-red;
}

.status-indicator.text-label-orange::before {
	@apply bg-label-orange;
}

.status-indicator.text-label-gray::before {
	@apply bg-lightBlue-450;
}

.status-advisor::before {
	content: '';
	@apply absolute w-3 h-3 bottom-0 right-0 rounded-full bg-label-green border-2 border-solid border-white;
}

.status-advisor-big::before {
	content: '';
	@apply absolute w-3_4 h-3_4 bottom-0 right-1 rounded-full bg-label-green border-2 border-solid border-white;
}
