@import '../node_modules/@reach/accordion/styles.css';

.rotateIconOnOpen {
	@apply transform transition-transform rotate-0 duration-300;
}
.boldOnSelected {
	@apply text-left;
}

[data-state*='open'] {
	.boldOnSelected {
		@apply font-bold;
	}
	.rotateIconOnOpen {
		@apply rotate-180;
	}
}
