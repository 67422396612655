.form-checkbox {
	background-image: none;
	border-color: #343a4080;

	&--green {
		@apply border-green-500;

		&:checked {
			@apply border-green-500 bg-green-500;
		}
	}
}
