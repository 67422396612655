.divider {
	@apply flex items-center text-center;
}

.divider::after,
.divider::before {
	content: "";
	@apply border-0_5 border-gray-18 flex-1;
}

.divider:not(:empty)::before {
	@apply mr-2_5;
}

.divider:not(:empty)::after {
	@apply ml-2_5;
}
